@use 'variables';
.component-checkoutBottomSummary {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 16px;
    @include variables.for-tablet-landscape-up {
        margin: 30px 0px 0px 0px;
    }

    @include variables.for-tablet-portrait-up {
        h3 {
            display: none;
        }
    }

    &__container {
        padding: 12px 0px 8px 0px;
    }

    &__parcel,
    &__rademar {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        p {
            margin-bottom: 5px;
        }

        span {
            flex: 0 0 100%;
            margin: 5px 0px 0px 0px;
            line-height: 24px;
            @include variables.bold-font;
        }
    }

    &__method {
        margin-bottom: 20px;
        p {
            margin-bottom: 5px;
        }

        span {
            flex: 0 0 100%;
            margin: 5px 0px 0px 0px;
            line-height: 24px;
            @include variables.bold-font;
        }
    }

    &__courier {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        p {
            margin: 0px 0px 5px 0px;
            line-height: 24px;
            span {
                @include variables.bold-font;
            }

            &:nth-child(2) {
                @include variables.bold-font;
            }
        }
    }

    &__sum {
        display: flex;
        flex-direction: column;

        p {
            margin: 0px 0px 12px 0px;
            &:last-child {
                margin-top: 8px;
                font-size: 30px;
                color: #00A053;
                @include variables.bold-font;
                span {
                    font-size: 30px;
                    color: #00A053;
                    margin-top: 10px;
                    margin-left: 5px;
                }
            }
        }

        span {
            margin-left: 5px;
            @include variables.bold-font;
        }
    }
}
