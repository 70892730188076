@use 'variables';

.component-checkoutForm {
    @include variables.for-tablet-landscape-up {
        margin: 30px 0px;
    }

    h3 {
        font-size: 22px;
    }

    &-header {
        display: flex;
        flex: 0 0 100%;
        @include variables.for-tablet-landscape-up {
           h3 {
                display: none;
           }
        }
    }

    .alert {
        margin-top: 10px;
    }

    &-fields {
        display: flex;
        flex-wrap: wrap;
        margin: 0px -5px 0px -5px;

        .component-checkoutFormInput {
            &:nth-child(3) {
                flex: 0 0 100%;
            }
        }
    }

    &-errorText {
        color: #ff5350;
        padding: 10px 10px 5px 10px;
        line-height: 24px;
    }
}
