@use 'variables';

.component-checkoutTopSummary {
    position: relative;
    background-color: #fff;
    padding: 10px 0px;

    &__inner {
        display: none;
    }

    &__prices {
        display: flex;
                align-items: center;
                max-height: 50px;
                margin-left: auto;
                p {
                    span {
                        margin-left: 5px;
                        @include variables.bold-font;
                    }

                    &:nth-child(1)
                    &:nth-child(2) {
                        margin-right: 25px;
                    }
                }
    }
}
