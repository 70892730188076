@use 'variables';

.component-checkoutProducts {
    &-content {
        display: flex;
        flex-direction: column;
        padding-right: 45px;
        flex: 1;
        .price {
            margin-top: 20px;
            &__label {
                display: block;
            }
        }
        @include variables.for-tablet-landscape-up {
            max-width: 330px;
            padding-right: 0px;
            .price {
                display: none;
            }
        }
    }

    &-sizes {
        display: flex;
        margin-top: 10px;

        @include variables.for-tablet-landscape-up {
            .component-quantityButton {
                display: none;
            }
        }
    }

    &-size {
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 0.75rem;
        height: 35px;
        padding: 5px 10px;
        margin-right: 10px;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        @include variables.bold-font;
    }

    &-desktop {
        display: none;
        @include variables.for-tablet-landscape-up {
            display: grid;
            height: 35px;
            grid-template-columns: 193px 193px 193px;
            text-align: center;

            .price {
                &__container {
                    display: inline-flex;
                    flex-direction: column;
                    text-align: left;
                }
                &__label {
                    display: block;
                }
            }

            &__sum {
                font-size: 1rem;
                @include variables.bold-font;
            }
        }
    }

    &-remove,
    &-remove__desktop {
        width: 25px;
        height: 25px;
        margin-left: auto;
        background-color: #000;
        border-radius: 4px;
        position: absolute;
        right: 20px;
        cursor: pointer;
        @include variables.for-tablet-landscape-up {
            right: 0px;
            display: none;
        }
        &::before {
            position: absolute;
            content: '';
            width: 2px;
            border-radius: 1px;
            background-color: #fff;
            height: 14px;
            transform: rotate(45deg);
            left: 12px;
            top: 5px;
        }
        &::after {
            position: absolute;
            content: '';
            width: 2px;
            border-radius: 1px;
            background-color: #fff;
            height: 14px;
            transform: rotate(-45deg);
            left: 12px;
            top: 5px;
        }

        &__desktop {
            display: none;

            @include variables.for-tablet-landscape-up {
                position: relative;
                display: flex;
                margin-left: 0px;
                margin-top: 20px;
                width: auto;
                height: auto;
                background-color: transparent;
                cursor: default;

                p {
                    padding: 10px 0px;
                    border-radius: 4px;
                    cursor: pointer;
                    background-color: #fff;
                    color: #000;
                    font-size: 0.75rem;
                    @include variables.regular-font;
                    &:hover {
                        text-decoration: underline;
                    }
                }

                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }
}
