@use 'variables';

.component-paymentMethods {
    display: flex;
    flex-direction: column;
    flex: 1;
    @include variables.for-tablet-landscape-up {
        margin: 30px 0px;
    }

    &__title {
        @include variables.for-tablet-landscape-up {
            display: none;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        p {
            margin-left: auto;
            cursor: pointer;
            @include variables.bold-font;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0px -5px;

        .alert {
            flex: 0 1 100%;
            margin: 10px 5px 0px 5px;
        }
    }

    &__selected {
        display: flex;
        flex-direction: column;
        margin: -40px 0px 0px 0px;
        cursor: pointer;
        @include variables.for-tablet-landscape-up {
            margin: 15px 0px 25px 0px;
        }

        &-header {
            display: flex;
            align-items: center;
            flex: 1;
            border: 1px solid #00A053;
            border-radius: 4px;
            padding: 10px 20px;

            p {
                font-size: 0.875rem;
                line-height: 2.4rem;
                margin-left: 20px;
                padding-left: 20px;
                padding-right: 20px;
                border-left: 1px solid #e2e2e2;
                @include variables.bold-font;
            }
        }
    }

    &__bank {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border: 1px solid #00A053;
        border-radius: 4px;
        min-height: 70px;
    }

    &__option {
        position: relative;
        flex: 0 0 50%;
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            cursor: pointer;
        }

        input:checked ~ .component-paymentMethods__label {
            border: 1px solid #00A053;
        }
    }

    &__label {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        margin: 5px;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        min-height: 60px;
        max-height: 60px;
        padding: 10px 15px 10px 15px;
    }

    &__image {
        display: flex;
        justify-content: center;
        flex: 1;
        img {
            width: 100%;
            height: auto;
        }
    }

    &__summary {
        margin-top: 15px;
        margin-bottom: 25px;
        &-container {
            margin-top: 15px;
            padding: 20px 20px;
            background-color: #f7f7f7;
            border-radius: 4px;
        }

        &-item {
            margin-bottom: 15px;
            p {
                margin-bottom: 5px;
                span {
                    @include variables.bold-font;
                }
            }

            ul {
                li {
                    p {
                        font-size: 0.875rem;
                        line-height: 2.4rem;
                        margin-bottom: 0px;
                        @include variables.bold-font;
                    }
                }
            }
        }

        &-sum {
            p {
                display: flex;
                flex-direction: column;
                color: #000;
                font-size: 14px;
                margin-top: 15px;
                @include variables.regular-font;
                span {
                    font-size: 30px;
                    color: #00A053;
                    padding: 10px 0px 0px 0px;
                    @include variables.bold-font;
                }
            }
        }
    }
}
