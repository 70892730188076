@use 'variables';

.component-discountCode {
    position: relative;
    .alert {
        margin-top: 10px;
    }

    &__container {
        position: relative;
        display: flex;
        @include variables.for-tablet-landscape-up {
            min-width: 20rem;
        }

        &.email,
        &.phone {
            flex: 0 0 100%;
            @include variables.for-tablet-portrait-up {
                flex: 0 0 48%;
            }
        }
        .error-text {
            display: none;
        }
        &.error {
            label {
                color: #ff4a4a;
            }
            input {
                border-color: #ff4a4a;
            }
            .error-text {
                color: #ff4a4a;
                display: block;
                font-size: 0.75rem;
                margin-top: 5px;
                @include variables.regular-font;
            }
        }
        &.ok {
            input {
                border-color: #00A053 !important;
            }
        }
    }

    &__input {
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #e2e2e2;
        border-right: none;
        height: 55px;
        padding-left: 20px;
        flex: 1 1 65%;
        font-size: 1rem !important;
        @include variables.bold-font;
    }

    &__label {
        position: absolute;
        top: 50%;
        left: 22px;
        right: 0;
        transform: translateY(-50%);
        pointer-events: none;
        color: #959595;
        @include variables.bold-font;
    }

    &__button {
        background-color: #00A053;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid #00A053;
        border-left: none;
        color: #ffffff;
        cursor: pointer;
        display: block;
        text-align: center;
        text-decoration: none;
        padding: 10px 16px;
        @include variables.bold-font;
        &-title {
            font-size: 0.875rem;
            line-height: 21px;
        }
        &:disabled {
            background-color: #e2e2e2;
            border: 1px solid #e2e2e2;
            color: black;
            cursor: not-allowed;
        }
    }

    &__error {
        padding-top: 10px;
        p {
            line-height: 24px;
            color: #ff5350;
        }
    }

    &__validation {
        margin-top: 20px;
        @include variables.for-tablet-landscape-up {
            margin-top: 0px;
        }
        &-title {
            display: flex;
            margin-bottom: 5px;
            p {
                display: inline-flex;
                align-items: center;
                color: #00A053;
                line-height: 1.688rem;
                position: relative;
                @include variables.bold-font;
                &::before {
                    position: absolute;
                    content: '';
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: #00A053;
                    top: 50%;
                    right: -36px;
                    transform: translate(-50%, -50%);
                    background-image: url('../../../images/checkmark-white.svg');
                    background-position: center;
                    background-size: 12px;
                    background-repeat: no-repeat;
                }
            }
        }

        p {
            display: block;
            line-height: 1.688rem;
            @include variables.bold-font;
        }

        &-remove {
            margin-top: 5px;
            p {
                display: inline-flex;
                cursor: pointer;
                @include variables.regular-font;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-fail {
            margin: 10px 0px;
            p {
                color: #ff4a4a;
            }
        }
    }
}
