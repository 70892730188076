@use 'variables';

.component-deliveryOptions {
    display: flex;
    flex-direction: column;
    flex: 1;
    @include variables.for-tablet-landscape-up {
        margin: 30px 0px;
    }

    &-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &__title {
            flex: 0 0 100%;
        }
        @include variables.for-tablet-landscape-up {
            margin-bottom: 0;
            &__title {
                display: none;
            }
        }
    }

    &-group {
        margin-top: 5px;
        &__header {
            display: flex;
            align-items: center;
            border-radius: 4px;
            cursor: pointer;
            padding: 10px 0px 5px 0px;
        }

        &__title {
            h3 {
                font-size: 1.6rem !important;
                line-height: 2.6rem;
            }
            p {
                font-size: 0.875rem;
                color: #959595;
                line-height: 1.25rem;
                @include variables.bold-font;
            }
        }

        &__content {
            .alert {
                margin-top: 10px;
            }
        }
    }

    &-list {
        .component-checkoutFormCountry {
            margin: 10px 0px;
        }
        .alert {
            margin-top: 15px;
        }
    }

    &-item {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        h4 {
            flex: 0 0 100%;
            padding-bottom: 5px;
        }
    }

    &-shipper {
        padding: 10px 20px;
        position: relative;
        flex: 0 0 100%;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        margin: 5px 0px;

        &__input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
        }

        &__label {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__logo {
            display: flex;
            justify-content: center;
            height: 40px;
            img {
                max-width: 45px;
                min-width: 45px;
            }
        }

        &__titles {
            margin-left: 20px;
            line-height: 24px;
            @include variables.bold-font;
        }

        &__rademar {
            color: #00A053;
        }

        &__checkbox {
            position: relative;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            margin-left: auto;
            width: 25px;
            height: 25px;
            z-index: -1;

            background-repeat: no-repeat;
            background-position: center;
            background-size: 18px;
        }

        &__details {
            padding: 20px;
            margin-top: 10px;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            p {
                line-height: 1.688rem;
                @include variables.regular-font;
                &:first-child {
                    @include variables.bold-font;
                }
            }
        }
    }

    &-dropdown {
        width: 100%;
        margin-top: 15px;
        box-shadow: none !important;
        &__value-container {
            height: 55px;
            padding-left: 15px !important;
            cursor: pointer;
            :focus {
                outline: none!important;
                box-shadow: none !important;
              }
        }

        &__option {
            display: flex !important;
            align-items: center !important;
            padding-left: 15px !important;
            height: 55px;
            cursor: pointer !important;
            @include variables.bold-font;
            &:hover {
                background-color: #00A053;
                color: #fff;
            }
            &--is-focused,
            &--is-selected {
                background-color: #00A053 !important;
                color: #fff !important;
            }
        }

        &__control {
            border: 1px solid #e2e2e2 !important;
            box-shadow: none !important;
            &--menu-is-open {
                box-shadow: none !important;
            }
        }

        &__placeholder {
            color: #000 !important;
            @include variables.bold-font;
        }

        &__single-value {
            color: #000 !important;
            @include variables.bold-font;
        }
    }

    &-shippingForm {
        .component-checkoutFormInput {
            padding: 10px 5px 0px 5px;
            &:first-child {
                flex: 0 0 100%;
            }
        }

        &__container {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -5px;
        }

        .alert {
            margin-top: 15px;
        }
    }

    &-selected {
        margin: 10px 0px 0px 0px;
        @include variables.for-tablet-landscape-up {
            margin: 20px 0px;
        }

        &__option {
            display: flex;
            align-items: center;
            border: 1px solid #00A053;
            border-radius: 4px;
            padding: 10px 20px;
        }

        &__header {
            display: flex;
            margin-bottom: 15px;
        }

        &__logo {
            img {
                max-width: 40px;
                min-width: 40px;
            }
        }

        &__titles {
            line-height: 24px;
            margin-left: 20px;
            @include variables.bold-font;
        }

        &__rademar {
            color: #00A053;
        }

        &__checkbox {
            position: relative;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            margin-left: auto;
            width: 25px;
            height: 25px;
            z-index: -1;
            background-repeat: no-repeat;
            background-image: url('../../../images/checkmark-white.svg');
            background-color: #00A053;
            border: 1px solid #00A053;
            background-size: 18px;
            background-position: center;
        }

        .alert {
            margin-top: 15px;
        }
    }
}
