@use 'variables';

.component-checkout {
    position: relative;
    margin: 20px auto;
    max-width: 1024px;

    h3 {
        font-size: 22px;
    }

    &__title {
        font-size: 36px;
        margin-bottom: 20px;
        @include variables.for-tablet-landscape-up {
            margin-bottom: 40px;
            margin-top: 40px;
        }
    }

    &__products {
        @include variables.for-tablet-landscape-up {
            margin-bottom: 50px;
        }
    }

    &__sticky {
        position: sticky;
        top: 60px;
        z-index: 2;
        @include variables.for-tablet-portrait-up {
            top: 120px;
        }
        @include variables.for-tablet-landscape-up {
            top: 175px;
        }
    }

    &__header {
        @include variables.for-tablet-landscape-up {
            display: none;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;

        &-container {
            @include variables.for-tablet-landscape-up {
                max-width: 900px;
                margin-left: -20px;
            }
        }

        &-item {
            display: flex;
            margin-top: 30px; 
            @include variables.for-tablet-landscape-up {
                margin-top: 0px;
            }
        }
    }

    &__box {
        display: none;
        @include variables.for-tablet-landscape-up {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 0 0 280px;
            margin-right: 30px;

            &-title {
                font-size: 26px;
                text-align: center;
                @include variables.bold-font;
            }
            &-line {
                margin: 30px 0px;
                width: 2px;
                height: 100%;
                border-left: 2px dashed #e2e2e2;
            }
        }
    }
}
