@use 'variables';

.component-checkoutFormInput {
    flex: 1 0 50%;
    padding: 5px;

    &.ok {
        .component-checkoutFormInput__input {
            border: 1px solid #00A053;
            background-color: #fff;
        }
        .component-checkoutFormInput__tick {
            display: block;
        }
    }

    &.error {
        .component-checkoutFormInput__input {
            border: 1px solid #ff5350;
            background-color: #fff;
            &::placeholder {
                color: #ff5350 !important;
                opacity: 1;
            }
        }
    }

    &__inner {
        position: relative;
    }

    &__input {
        position: relative;
        width: 100%;
        border: 1px solid #e2e2e2;
        background-color: #fff;
        border-radius: 4px;
        font-size: 14px;
        padding: 28px 10px 10px 15px;
        max-height: 55px;
        @include variables.bold-font;

        &::placeholder {
            font-size: 0.875rem;
            color: #000;
            @include variables.bold-font;
        }

        &[type='text'] {
            padding-top: 28px;
        }

        &:focus ~ .component-checkoutFormInput__label {
            transform: translateY(-17px);
            font-size: 10px;
            transition: all 0.1s;
        }

        &:disabled {
            background-color: #f5f5f5;
            padding: 25px 0px 25px 15px;
        }
    }

    &__label,
    &__label--active {
        position: absolute;
        font-size: 14px;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
        color: #959595;
        transition: all 0.1s;
        pointer-events: none;
        @include variables.bold-font;

        &--active {
            transform: translateY(-17px);
            font-size: 10px;
            transition: all 0.1s;
        }
    }
}
