@use 'variables';

.component-checkoutPaymentBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #fff;
    width: 100%;
    padding: 13px 20px;
    background-color: #00A053;
    font-size: 16px;
    line-height: 26px;
    margin: 10px 0px 20px 0px;
    cursor: pointer;
    @include variables.bold-font;
    &--disabled {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: #fff;
        width: 100%;
        padding: 13px 20px;
        font-size: 16px;
        line-height: 26px;
        margin: 10px 0px 20px 0px;
        cursor: pointer;
        background-color: #e2e2e2;
        color: black;
        @include variables.bold-font;
    }

    &-validation {
        position: relative;
        margin: 10px 0px 20px 0px;

        &.error {
            .component-checkoutPaymentBtn-validation {
                &__label {
                    p {
                        color: #ff5350;
                    }
                }

                &__checkbox {
                    border: 1px solid #ff5350;
                }
            }
        }

        &__input {
            position: absolute;
            width: 35px;
            height: 35px;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 1;

            &:checked ~ .component-checkoutPaymentBtn-validation__label {
                .component-checkoutPaymentBtn-validation__checkbox {
                    background-image: url('../../../images/checkmark-white.svg');
                    background-color: #00A053;
                    border: 1px solid #00A053;
                    background-repeat: no-repeat;
                    background-size: 20px 20px;
                    background-position: center;
                }
            }
        }

        &__label {
            display: flex;
            align-items: center;
            p {
                margin-left: 20px;
            }

            a {
                text-decoration: underline;
            }
        }

        &__checkbox {
            position: relative;
            width: 35px;
            height: 35px;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}
